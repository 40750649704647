import React from 'react'
import Layout from 'src/components/Layout'
import image404 from 'assets/images/404.png'

const NotFound = () => {
  const contentMetas = {
    slug: '/NotFound',
    metaTitle: 'Inter. Simplifica a vida.',
    metaDescription: '',
    noIndexOption: true,
    hideOpenAccountBottomForm: true,
    hideBabiChat: false
  }

  return (
    <Layout pageContext={contentMetas}>
      <section className='pt-4 py-lg-5 text-center text-lg-left'>
        <div className='container pt-xl-5'>
          <div className='row'>
            <div className='col-12'>
              <h3 className='fs-30 fs-lg-42 text-grayscale--500 mb-md-5'>Ops... página não encontrada!</h3>
            </div>
            <div className='col-sm-12 col-lg-5 col-xl-6 order-lg-2 pb-4 pb-lg-0 text-center mt-lg-n4 mt-xl-n5'>
              <img className='img-fluid' src={image404} alt='404 image' />
            </div>
            <div className='col-sm-12 col-lg-7 col-xl-6 order-lg-1'>
              <p className='fs-18 lh-22 fs-lg-20 text-grayscale--500'>Aqui estão alguns links que você pode estar procurando:</p>
              <ul className='list-unstyled link-list--404 fw-400'>
                <li>
                  <a href='/' title='Home'>Home</a>
                </li>
                <li>
                  <a href='/categories/cartao' title='Conta Digital'>Cartões</a>
                </li>
                <li>
                  <a href='/categories/credito-imobiliario' title='Empréstimos'>Crédito Imobiliário</a>
                </li>
                <li>
                  <a href='/pra-voce/financiamento-imobiliario/residencial/' title='Financiamento Imobiliário'>Financiamento Imobiliário</a>
                </li>
                <li>
                  <a href='https://www.bancointer.com.br/pra-voce/investimentos/' title='Investimentos'>Investimentos</a>
                </li>
                <li>
                  <a href='https://www.bancointer.com.br/pra-voce/seguros/' title='Seguro'>Seguro</a>
                </li>
                <li>
                  <a href='https://www.bancointer.com.br/cambio/' title='Câmbio'>Câmbio</a>
                </li>
                <li>
                  <a href='https://www.bancointer.com.br/consorcio/' title='Consórcio'>Consórcio</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFound
